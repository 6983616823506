import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`OK. I confess. It's now been one month since Monster Hunter: Rise released and I've been playing it `}<em parentName="p">{`A LOT`}</em>{`. I'm not at roughly ~120 hours on my main hunter, with a few extra thrown in on my female hunter. Since we last `}<a parentName="p" {...{
        "href": "../MonsterHunterRiseRelease"
      }}>{`spoke`}</a>{` I've switched from the weapon I took through the whole game, the hunting horn, back over to my first love, the switch axe. Also surprising, mostly to me, is that I've already eclipsed the number of hunts I did with the hunting horn.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/711b4686d422bd08f41fb0987e81018d/eea4a/weapon-usage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB6jSsbpIFf//EABoQAAMAAwEAAAAAAAAAAAAAAAECEQADEiH/2gAIAQEAAQUC4Egx0WhjL7sduv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/Aaf/xAAXEAADAQAAAAAAAAAAAAAAAAAAATEQ/9oACAEBAAY/AiIgt//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExQaHh/9oACAEBAAE/IVBg9AYsblwUEIT6P//aAAwDAQACAAMAAAAQJ+//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QSNv/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QBOX/xAAcEAEAAwACAwAAAAAAAAAAAAABABExIUFRcdH/2gAIAQEAAT8Qym0M6gRXMBVCK6M6iUd+EH5o+wisYVgPk//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Weapon Usage",
            "title": "Weapon Usage",
            "src": "/static/711b4686d422bd08f41fb0987e81018d/af659/weapon-usage.jpg",
            "srcSet": ["/static/711b4686d422bd08f41fb0987e81018d/8356d/weapon-usage.jpg 259w", "/static/711b4686d422bd08f41fb0987e81018d/bc760/weapon-usage.jpg 518w", "/static/711b4686d422bd08f41fb0987e81018d/af659/weapon-usage.jpg 1035w", "/static/711b4686d422bd08f41fb0987e81018d/eea4a/weapon-usage.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Switch Axe`}</h4>
    <p>{`This bad boy saw a few tweaks from Iceborne that I'm pretty happy about. I did cover some of this over in my `}<a parentName="p" {...{
        "href": "../MonsterHunterRise"
      }}>{`demo review`}</a>{` but what I couldn't have known from the demo was just how awesome on the of the switch skills would be: Soaring Wyvern Blade. This thing let's you basically do a longsword helm splitter so long as you have a single wirebug charged. It gets you over a lot, certainly not all, monster attacks and does really good mounting damage and charges your sword mode quite a bit.`}</p>
    <p>{`I mentioned in that demo review that it would be unlikely Capcom would give us a Demon Riot/Energy Charge combo, but they came really close. Between the sword charge of SWB and the switch gauge filling of Switch Charger it's almost the same thing. Unless you're just not paying attention to your wirebugs + switch gauge it is nearly impossible not to be in sword mode, if that's what you want. Also unknown at demo time was the existence of the Rapid Morph skill. This thing does so much for the Switch Axe it's incredible. It speeds up how quickly you morph from axe to sword and back again while increasing the damage of each of those attacks. It opens up a new, simple, combo that you can loop indefinitely and deal tons of damage and phial bursts. `}</p>
    <p>{`The last big change the weapon saw, was a slight nerf in the rate at which you built sword charge for power phial swaxes. While this balances about the bigger damage numbers you get once charged (and the big bit of charge you get from SWB), I personally am finding it way more satisfying to use the Conqueress with it's exhaust phials and get one, and occasionally two, KOs per hunt.`}</p>
    <h4>{`Mobility`}</h4>
    <p>{`While palamutes are fun, and my Almudron encased doggo looks like a robot, just getting around the maps with wirebugs alone is just as awesome. I'm familiar enough now the maps and have unlocked all the camps, that I don't really lose out on speed when chasing a monster or jumping around to pick up resource nodes. Getting around is just too smooth right now, and I never want to go back.`}</p>
    <h4>{`Armor`}</h4>
    <p>{`This entry marks the first time I've started a Monster Hunter as a veteran, so I set myself the goal of acquiring every armor set in the game. As of early last week, I accomplished that for every single large monster and most of the small monster/Argosy/Meowcenary armor sets. There are a few meowcenary sets that I'm still missing because I'm not getting enough of the special resources, but these I know I'll get over time without issue. It was definitely a long grind and I wasn't quite sure that the Rathalos Ruby even existed for a while, but it's nice to know that whenever I see a set on the interwebs, I'm probably only a talisman away.`}</p>
    <h4>{`Talisman Grind`}</h4>
    <p>{`Speaking of Talismans, it's undeniable that these little blighters are the true grind of Rise. Personally, I am a fan of this approach over World/Iceborne's, since you can get most of a set with the armor plus the decorations. I just wish there were more ways to acquire these things. The internet tells me that you are placed in one of a few tables when creating your save game and that the talismans you have access to are limited. Some of the best talismans are not even available to you unless you use Rebirth melding, which takes a whopping 20 junker talismans to get a chance at every talisman. It would be nice if there was an event quest or something in the future that dropped random talismans at the end of it or something, or even just one. I have no issue with the random drop system itself other than there are just not enough ways to engage in it.`}</p>
    <p>{`All that being said, I did get a pretty sweet talisman myself: a WEX2, Leap of Faith 1-1-1 as seen as part of my build here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0289befac7876be948420947f753a70b/eea4a/swaxe-build.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHEjUojFIP/xAAYEAADAQEAAAAAAAAAAAAAAAAAAQIDE//aAAgBAQABBQJvWF30Hpbbp0coOcH/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFX/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECETIhkf/aAAgBAQAGPwLUkclL067KbMoyj//EABsQAAMAAgMAAAAAAAAAAAAAAAABESHhMYHB/9oACAEBAAE/IajK6NrlU1jJJq8Cj5C0B//aAAwDAQACAAMAAAAQoO//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QZH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCqf//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhYZH/2gAIAQEAAT8QpUXCrZ8vYiUJHbI8xy1WEnX9nktnvvxFCu8f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "My Build",
            "title": "My Build",
            "src": "/static/0289befac7876be948420947f753a70b/af659/swaxe-build.jpg",
            "srcSet": ["/static/0289befac7876be948420947f753a70b/8356d/swaxe-build.jpg 259w", "/static/0289befac7876be948420947f753a70b/bc760/swaxe-build.jpg 518w", "/static/0289befac7876be948420947f753a70b/af659/swaxe-build.jpg 1035w", "/static/0289befac7876be948420947f753a70b/eea4a/swaxe-build.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Still the Worst`}</h4>
    <p>{`Someone please give me a way to stop the cantina song. It still drives me insane, and the fact that it's my son's favorite part of the game isn't helping matters at all as I'm not allowed to skip the animation.`}</p>
    <h4>{`Final Thoughts`}</h4>
    <p>{`Even after 120 hours and many of my builds being as complete as they can be without god charms, I'm still enjoying the game immensely and I can't wait until the end of this week/month when the first title update drops and we get access to more monsters to turn into pants.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d12a6c7880a9b017cac5df8fa62e21cc/eea4a/me.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABvY75QK//xAAbEAACAQUAAAAAAAAAAAAAAAABAgQAEBIUMf/aAAgBAQABBQLZyVZTgmQa4Rb/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABoQAAEFAQAAAAAAAAAAAAAAAAIAARARIWH/2gAIAQEABj8CohxU4tXIyf/EABsQAQACAgMAAAAAAAAAAAAAAAEAESExUYGR/9oACAEBAAE/IULYRqEbF4hGsdwAaBTCGicz/9oADAMBAAIAAwAAABAf7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBBYX//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAUf//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExcUFRkf/aAAgBAQABPxAcLIlxbKt9TwEsBEVlQFZ5yEX4TBQAoMyCNBbb3OHs8z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Me",
            "title": "Me",
            "src": "/static/d12a6c7880a9b017cac5df8fa62e21cc/af659/me.jpg",
            "srcSet": ["/static/d12a6c7880a9b017cac5df8fa62e21cc/8356d/me.jpg 259w", "/static/d12a6c7880a9b017cac5df8fa62e21cc/bc760/me.jpg 518w", "/static/d12a6c7880a9b017cac5df8fa62e21cc/af659/me.jpg 1035w", "/static/d12a6c7880a9b017cac5df8fa62e21cc/eea4a/me.jpg 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      